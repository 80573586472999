.register {
    .logo-img {
        width: 6.875vw !important;
        height: 6.875vw !important;
    }

    .register-btn {
        color: #fff;
        text-decoration: none;
    }

    .main_heading {
        margin-top: 11.25vw;
    }

    .admin-heading {
        margin-top: 16.25vw;
        padding-right: 5vw;
    }

    .admin-rightsidepad {
        margin-top: 9.0277vw;
    }
}

@media only screen and (max-width:899px) {
    .register {
        .logo-img {
            width: 45px !important;
            height: 45px !important;
        }
    }
}