.resetpassword{
    
  // .logo-img{
  //   width: 2.778vw !important;
  //   height: 2.778vw !important;
  // }
  .logopadding{
    padding: 1.66vw !important;
    max-width: 33.3vw;
  }
  .toppadd_reset{
    padding: 19.722vw 0;
  }
  .resetpara{
    width: 32vw;
    margin: 0 auto 2.778vw auto;
  }
  .resetbtn{
    color: #fff;
    font-size: 15px;
    line-height: 26px;
    font-weight: 700;
    text-decoration:none;
  }
}

// new password 

.newpassword{
  // .logo-img{
  //   width: 2.778vw !important;
  //   height: 2.778vw !important;
  // }
  .logopadding{
    padding: 1.66vw !important;
  }
  .parapadding{
    padding: 0 2vw;
  }
}

@media only screen and (max-width:899px){
    .resetpassword{
        .resetpara{
            width: 100%;
            margin-bottom: 40px;
        }
        .toppadd_reset {
            padding: 215px 0 0;
        }
        // .logo-img{
        //     width: 40px !important;
        //     height: 40px !important;
        //   }
    }

    .newpassword{
      // .logo-img{
      //   width: 40px !important;
      //   height: 40px !important;
      // }
    }
}