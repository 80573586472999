// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.login{
   
    .boxpadding{
        // padding: 27px 40px;
        padding:1.875vw 2.778vw  ;
    }
    .welcome_text{
        margin-top: 14.806vw;
        // padding-left: 1.250vw;
        // margin-bottom: 3.056vw;
        font-size: 2.222vw;
        line-height: 3.333vw;
        font-weight: 700;
        padding-right: 10vw;
        padding-left:3.875vw;
    
    }
    .login_sectionimg{
        width: 13.612vw;
        height: 23.542vw;
        margin: auto;
        object-fit: contain;
    }
    .logo-img{
        width: 6.875vw !important;
        height: 6.875vw !important;
    }
    .signin_text{
        font-size: 1.66vw;
        line-height: 2.5vw;
        margin-bottom: 0vw;
        // color: #212B36;
        margin-top: 7vw;
    }
    .details_text{
        font-size: 1.11vw;
        line-height: 1.66vw;
    }
    .account-text{
        font-size: 0.972vw;
        margin-top: 0;
    }
  
}

@media only screen and (max-width:899px){
    .login{
        .boxpadding{
            padding:1.875vw 2.778vw  ;
        } 
        .logo-img{
        width: 45px !important;
       height: 45px !important;
        }
        .signin_text{
            font-size: 20px;
            line-height: 30px;
            margin-top: 0;
            margin-bottom: 8px;
        }
        .mobilepadd{
            padding: 82px 0px;
        }
        .details_text{
            font-size: 16px;
            line-height: 24px;
        }
       
    }
}