.error404{
    .error404_img{
        height: 18.055vw;
        margin: 5.55vw 0;
    }
    .error404_img2{
        height: 18.055vw;
    margin: 5.55vw auto;
    width: 22.75vw;
    object-fit: contain;
    }
}
@media only screen and (max-width:899px){
    .error404{
        .error404_img{
            margin: 80px 0;
            height: 245px;
        }
        .error404_img2{
            margin: 80px 0;
            height: 245px;
            width: 100%;
        }
    }
}