@charset "UTF-8";

// 1. Configuration and helpers
// @import
//   'abstracts/variables',
//   'abstracts/functions',
//   'abstracts/mixins';

// 2. Vendors
// @import
//   'vendors/normalize';

// 3. Base stuff
// @import
//   'base/base',
//   'base/fonts',
//   'base/typography',
//   'base/helpers';

// 4. Layout-related sections
@import 'layout/header', 'layout/footer';

// 5. Components
@import 'components/button';

// 6. Page-specific styles
@import 'pages/login', 'pages/_resetPassword.scss', 'pages/register', 'pages/verifyCode',
  'pages/_pricing.scss', 'pages/error';

// 7. Themes
@import 'themes/default';
@import 'bourbon';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
.form-container {
  // width: 80vw;
  // height: 100vh;
  // background-color: #3be446;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tdwidth{
  td{
    width: 14.2%;
  }
}
.upload-files-container {
  background-color: #f7fff7;
  width: 400px;
  margin-right: 30px;
  padding: 30px 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow:
    rgba(0, 0, 0, 0.24) 0px 10px 20px,
    rgba(0, 0, 0, 0.28) 0px 6px 6px;
}
.drag-file-area {
  border: 2px dashed #7b2cbf;
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 350px;
  text-align: center;
}
.drag-file-area .upload-icon {
  font-size: 50px;
}
.drag-file-area h3 {
  font-size: 26px;
  margin: 15px 0;
}
.drag-file-area label {
  font-size: 19px;
}
.drag-file-area label .browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}
.browse-files span {
  position: relative;
  top: -25px;
}
.default-file-input {
  opacity: 0;
}
.cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #bb0000;
  display: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cannot-upload-message span,
.upload-button-icon {
  padding-right: 10px;
}
.cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}
.file-block {
  color: #f7fff7;
  background-color: #7b2cbf;
  transition: all 1s;
  width: 390px;
  position: relative;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}
.file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.file-icon {
  margin-right: 10px;
}
.file-name,
.file-size {
  padding: 0 3px;
}
.remove-file-icon {
  cursor: pointer;
}
.progress-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 4.5%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4bb543;
}
.upload-button {
  font-family: 'Montserrat';
  background-color: #7b2cbf;
  color: #f7fff7;
  display: flex;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}
//calender css
.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.css-dbk7ja-MuiTableCell-root {
  width: 93px;
}
//main section css
.section-style {
  max-width: 32.22vw !important;
}
.read-morebtn {
  border: none;
  background: transparent;
  cursor: pointer;
  // color: #229a16;
}
.time_text {
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-right: 1rem;
}
.container-style {
  max-width: 33.33vw !important;
}

// end main section css
.graphdots {
  align-items: center !important;
}
// all heading css
.MuiTypography-root.main_heading {
  font-size: 2.222vw;
  line-height: 3.333vw;
  font-weight: 700;
}

.MuiTypography-root.heading_24 {
  font-size: 1.66vw;
  line-height: 2.5vw;
  font-weight: 700;
}
.brundow {
  min-width: 310 px;
  height: 400px;
  margin: 0 auto;
}
.MuiTypography-root.heading_16 {
  font-size: 1.11vw;
  line-height: 1.66vw;
  font-weight: 400;
}
.logList {
  font-size: 1.25rem !important;
  width: max-content;
  font-weight: 800 !important;
}
.MuiTypography-root.heading_14 {
  font-size: 0.9722vw;
  line-height: 1.527vw;
}

.MuiTypography-root.heading_12 {
  font-size: 0.833vw;
  line-height: 1.25vw;
}

// all heading css end

// placeholder css

::-webkit-input-placeholder {
  /* Edge */
  font-size: 1.11vw;
  line-height: 1.66vw;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 1.11vw;
  line-height: 1.66vw;
}

::placeholder {
  font-size: 1.11vw;
  line-height: 1.66vw;
}
.alloted-time {
  display: flex;
  align-items: center;
}
//placeholder css end

//social login code
.social-box {
  // display: inline-block;
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  padding: 0.85vw 0vw;
  margin-right: 0.694vw;
  align-items: center;
  display: flex;
  justify-content: center;
}

.social_icon {
  width: 1.66vw !important;
  height: 1.66vw !important;
  object-fit: contain !important;
}

.social-section {
  margin-top: 2.778vw;
  margin-bottom: 2vw;
}
img.logo-img {
  width: 100% !important;
  object-fit: contain;
  min-height: 6vw !important;
  padding: 0.5vw;
}
.logo-img {
  width: 8.875vw !important;
  height: 2.875vw !important;
}

.only_social_section {
  fieldset {
    border: 1px solid transparent;
    border-top-color: rgba(145, 158, 171, 0.24);
    box-sizing: border-box;
    grid-area: 1 / 1;
    width: inherit;
    margin-bottom: 2vw;
  }

  fieldset:nth-of-type(2) {
    transform: rotate(90deg);
  }

  legend {
    margin: auto;
    padding: 0 0.64vw;
    text-align: center;
    font-size: 0.9722vw;
    line-height: 1.527vw;
    font-weight: 600;
    color: #637381;
  }
}

.card_height {
  height: 20vw;
}

//social login code end
@media only screen and (max-width: 899px) {
  .section_style {
    max-width: 95% !important;
    margin: auto;
  }

  .container-style {
    max-width: 98% !important;
    margin: auto;
  }

  // all heading css
  .MuiTypography-root.main_heading {
    font-size: 24px;
    line-height: 36px;
  }

  .MuiTypography-root.heading_24 {
    font-size: 24px;
    line-height: 32px;
  }

  .MuiTypography-root.heading_16 {
    font-size: 16px;
    line-height: 24px;
  }

  .MuiTypography-root.heading_14 {
    font-size: 14px;
    line-height: 22px;
  }

  .MuiTypography-root.heading_12 {
    font-size: 12px;
    line-height: 18px;
  }

  // placeholder css

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px;
    line-height: 24px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    line-height: 24px;
  }

  ::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  //placeholder css end

  //social login code
  .social_icon {
    width: 24px !important;
    height: 24px !important;
    object-fit: scale-down !important;
  }

  .social-box {
    margin-right: 10px;
    padding: 8px 0;
  }

  .social-section {
    margin-top: 40px;
    margin-bottom: 34px;
  }

  .only_social_section {
    fieldset {
      margin-bottom: 34px;
    }

    legend {
      font-size: 14px;
      line-height: 22px;
    }
  }

  //social login code end

  .logo-img {
    width: 40px !important;
    height: 40px !important;
  }

  .card_height {
    height: 250px;
  }
}
.dashboard {
  .slick-slider .MuiBox-root {
    right: 24px;
    bottom: 24px;
    align-items: end;
    justify-content: end;
    top: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1600px) {
  // placeholder css

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 1.66vw;
    line-height: 2vw;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 1.66vw;
    line-height: 2vw;
  }

  input::placeholder::placeholder {
    font-size: 1.66vw;
    line-height: 2vw;
  }
}

.timeBTn {
  color: #212b36 !important;
  background-color: transparent !important;
  padding-left: 0 !important;
  font-weight: 400 !important;
}
// .rbc-event-label {
//   display: none;
// }
// .rbc-agenda-time-cell{
//   display: none !important;
// }
// th.rbc-header.time {
//     width: 0px;
//     display: none !important;
// }

.fc .fc-list-sticky .fc-list-day > * {
  position: sticky;
  top: 0;
  background: #fff;
  background: var(--fc-page-bg-color, #3788d8) !important;
}

.fc .fc-list-event:hover td {
  background-color: #fff !important ;
  background-color: transparent !important;
}

.Filter {
  float: right !important;
  cursor: pointer;
}

.main_container {
  clear: both !important;
}

.dropdown_container {
  max-width: 100% !important;
  .MuiFormControl-root.MuiTextField-root {
    display: flex;
  }
}
.stack-center {
  align-items: center !important;
}

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  // background: rgb(218, 120, 220);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: nowrap;
}
.task-card-section {
  .task-card {
    display: inline-block;
    width: 31%;
    margin: 1% 1%;
  }
  .card-heading {
    cursor: pointer;
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .new-card-heading {
    text-transform: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c4cdd5;
    border-radius: 10px;
    background: #c4cdd5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #919eab;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919eab;
  }
}

.sidebar-inputfield {
  .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon {
    .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
      max-height: 200px;
      overflow-y: auto;
    }
  }
}
